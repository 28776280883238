import React from 'react';

import { useSearch } from '../../hooks/context';
import VisFacetedSearchDesktop from '../intervention/faceted-search/vis-faceted-search.desktop';
import { LAYOUTS } from '../../constants';

const LAYOUT_TYPES = [LAYOUTS.TYPE_GRID, LAYOUTS.TYPE_STACK];

const FacetedSearchDesktopContainer = () => {
  const { new_faceted_search: newFacetedSearch, layout_options } = useSearch();

  const isAllowedLayout = LAYOUT_TYPES.includes(layout_options?.current);

  return isAllowedLayout && newFacetedSearch && newFacetedSearch?.faceted_config ? <VisFacetedSearchDesktop /> : null;
};

export default FacetedSearchDesktopContainer;
